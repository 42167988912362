.contact-us .sub-page-banner .section-text.video-hero--content {
  max-width: 653px;
}

.form-main-conatainer {
  padding: 90px 0 150px;
}

.form-inner-conatainer {
  max-width: 604px;
  width: 100%;
  margin: 0 auto;
  padding: 34px 33px 46px;
  border-radius: 6px;
}

.form-main-conatainer .section-heading h2,
.form-main-conatainer .form-heading h2 {
  font-size: 32px;
  line-height: 52px;
  text-align: center;
  color: #14144b;
  margin: 0 0 30px;
}

.hs-form .hs-input {
  max-width: 100%;
  width: 100% !important;
  background: #fff;
  border: 2px solid #757575;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 18px;
  line-height: 25px;
  color: #757575;
  font-family: 'AvenirNextRegular';
  padding: 13px 20px;
  margin: 4px 0px 4px 0px !important;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
}

.hs-form .hs-form-field {
  margin-bottom: 18px;
}

.hs-form textarea {
  height: 124px;
  resize: none;
}

.hs-form .hs-button {
  background: #d91e61;
  border-radius: 644px;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  padding: 8px 15px;
  box-sizing: border-box;
  border: 2px solid #d91e61;
  width: 100%;
  margin: 10px 0 0 0;
  font-family: 'AvenirNextDemiBold';
  font-weight: normal;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.hs-form .hs-button:hover {
  color: #d91e61;
  background-color: transparent;
}

.hs-form .input {
  margin-right: 0 !important;
}

.form-main-conatainer .section-text p {
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #222b56;
  max-width: 733px;
  margin: 0 auto 40px;
}

.hs-form fieldset {
  max-width: 100% !important;
}

.hs-error-msgs {
  margin: 0;
  list-style: none;
  padding: 0;
}

.hs-error-msgs .hs-error-msg {
  display: block !important;
  padding: 4px 0 0 8px;
  color: #d40000;
}

.hs-form fieldset.form-columns-3 .hs-form-field {
  width: calc(33.33% - 13.33px) !important;
  width: -webkit-calc(33.33% - 13.33px) !important;
  width: -moz-calc(33.33% - 13.33px) !important;
  margin-right: 19.99px;
}

.hs-form fieldset.form-columns-3 .hs-form-field:last-child {
  margin-right: 0;
}

.hs-form fieldset.form-columns-2 .hs-form-field {
  width: calc(50% - 6.5px) !important;
  width: -webkit-calc(50% - 6.5px) !important;
  width: -moz-calc(50% - 6.5px) !important;
  margin-right: 13px;
}

.hs-form fieldset.form-columns-2 .hs-form-field:last-child {
  margin-right: 0;
}

.hs_error_rollup {
  display: none;
}

.hs-form select.hs-input {
  background-image: url(https://cdn2.hubspot.net/hubfs/5871976/Peerfit%20-%20July%202020/Images/down-arrow.png);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 98% center;
  cursor: pointer;
}

.submitted-message {
  overflow: hidden;
  text-align: center;
  font-size: 22px;
  color: #222b56;
}

.half-image-text-main-container .half-img-text-section.reverse-module .half-col {
  padding: 0 15px;
  max-width: 554px;
}

.half-image-text-main-container .half-img-text-inner-section.right-side .half-img-section {
  text-align: right;
}

.half-image-text-main-container .half-img-text-inner-section.left-side .half-img-section {
  text-align: left;
}

.half-image-text-main-container .half-img-text-inner-section .half-img-section {
  line-height: 0;
}

.half-image-text-main-container .half-img-text-inner-section .half-img-section img {
  width: 100%;
}

.half-image-text-main-container .half-img-text-inner-section {
  justify-content: space-between;
  margin: 0 -15px;
}

.half-image-text-main-container .half-img-text-inner-section.left-side {
  flex-direction: row-reverse;
}

.half-image-text-main-container .btn-solid-bg {
  max-width: 255px;
}

.half-image-text-main-container .half-img-text-section.reverse-module {
  padding: 44px 0 136px;
}

.half-image-text-main-container {
  padding-bottom: 44px;
}

.half-image-text-main-container .half-img-text-section.reverse-module .half-text-inner-section {
  max-width: 443px;
}

@media (max-width: 1199px) {
  .form-main-conatainer {
    padding: 80px 0 120px;
  }

  .half-image-text-main-container .half-img-text-section.reverse-module {
    padding: 44px 0 56px;
  }
}

@media (max-width: 1024px) {
  .form-main-conatainer {
    padding: 70px 0 100px;
  }
}

@media (max-width: 991px) {
  .form-main-conatainer {
    padding: 60px 0 80px;
  }

  .form-main-conatainer .section-heading h2,
  .form-main-conatainer .form-heading h2 {
    line-height: 42px;
    margin: 0 0 20px;
  }

  .form-main-conatainer .section-text p {
    font-size: 22px;
    line-height: 36px;
  }

  .submitted-message {
    font-size: 20px;
  }

  .half-image-text-main-container .half-img-text-section.reverse-module {
    padding: 24px 0 56px;
  }

  .half-image-text-main-container {
    padding-bottom: 24px;
  }
}

@media (max-width: 880px) {
  .form-main-conatainer {
    padding: 60px 0 70px;
  }
}

@media (max-width: 767px) {
  .form-main-conatainer {
    padding: 50px 0 50px;
  }

  .form-main-conatainer .section-text p {
    font-size: 20px;
    line-height: 34px;
    margin: 0 auto 32px;
  }

  .form-main-conatainer .section-heading h2,
  .form-main-conatainer .form-heading h2 {
    line-height: 38px;
    font-size: 30px;
  }

  .hs-form .hs-button {
    font-size: 16px;
    line-height: 28px;
  }

  .half-image-text-main-container .half-img-text-inner-section.left-side {
    flex-direction: column-reverse;
  }

  .half-image-text-main-container {
    padding-bottom: 20px;
  }

  .half-image-text-main-container .half-img-text-section.reverse-module {
    padding: 20px 0 40px;
  }
}

@media (max-width: 640px) {
  .form-main-conatainer .section-text p {
    font-size: 18px;
    line-height: 30px;
    margin: 0 auto 28px;
  }

  .form-inner-conatainer {
    padding: 34px 30px 34px;
  }

  .hs-form fieldset.form-columns-3 .hs-form-field {
    width: calc(33.33% - 8px) !important;
    width: -webkit-calc(33.33% - 8px) !important;
    width: -moz-calc(33.33% - 8px) !important;
    margin-right: 12px;
  }
}

@media (max-width: 568px) {
  .hs-form .hs-input {
    padding: 9px 15px;
    font-size: 17px;
    margin-bottom: 2px;
  }

  .form-inner-conatainer {
    padding: 30px 25px 30px;
  }

  .hs-form .hs-form-field {
    margin-bottom: 14px;
  }

  .hs-form textarea {
    height: 100px;
  }

  .hs-form select.hs-input {
    background-size: 20px;
  }

  .submitted-message {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .hs-form .hs-button {
    line-height: 23px;
    margin: 6px 0 0 0;
  }

  .form-inner-conatainer {
    padding: 30px 15px 30px;
  }

  .form-main-conatainer .section-heading h2,
  .form-main-conatainer .form-heading h2 {
    line-height: 34px;
    font-size: 28px;
    margin: 0 0 16px;
  }

  .hs-form .hs-input {
    padding: 7px 15px;
    font-size: 16px;
  }

  .hs-form textarea {
    height: 80px;
  }

  .hs-form fieldset.form-columns-3 .hs-form-field {
    width: 100% !important;
    margin-right: 0;
  }

  .hs-form fieldset.form-columns-2 .hs-form-field {
    width: 100% !important;
    margin-right: 0;
  }

  .form-main-conatainer .section-text p {
    line-height: 26px;
  }

  .hs-form select.hs-input {
    background-size: 18px;
  }

  .form-main-conatainer {
    padding: 40px 0 40px;
  }

  .half-image-text-main-container .half-img-text-section.reverse-module {
    padding: 20px 0 30px;
  }
}
