.container {
  margin-bottom: 24px;
  margin-bottom: 2.4rem;
  border: 1px solid #ddd;
}
.content {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
  padding: 0 2rem;
  background: #fff;
}
.hasTitle {
  border-top: none;
}
.title {
  font-size: 20px;
  padding: 5px 32px;
  margin: 0;
  background: #edf4fd;
}
.titleLink {
  color: blue;
  font-size: 20px;
}
.section {
  width: 100%;
  margin-top: 0;
}
.sectionTitle {
  display: block;
  width: 200px;
  min-height: 1px;
  margin: 0;
  float: left;
  margin-right: 15px;
}
@media (max-width: 544px) {
  .sectionTitle {
    width: 100%;
    margin-right: 0;
    float: none;
  }
}
.sectionContent {
  padding: 0 8px 4px;
}
.lockIcon {
  width: 24px;
  height: 24px;
  float: right;
}

@media (max-width: 768px) {
  .title {
    padding: 16px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #1f274c;
  }
}
