.gotIt {
  display: inline-block;
}
.section {
  text-align: center;
}
.button {
  margin: 32px 0 0;
}
.dontShowAgain {
  height: 20px;
  height: 2rem;
  margin-left: 10px;
  margin-left: 1rem;
  float: right;
}
@media (max-width: 768px) {
  .dontShowAgain {
    display: block;
    float: none;
  }
}
.link {
  font-size: inherit;
  margin-left: 5px;
  text-decoration: underline;
}
@media (max-width: 768px) {
  .notification {
    text-align: left;
  }
  .title {
    padding: 0px 20px;
  }
}
