.outline {
  outline: 3px solid #2571cb;
  outline: 0.3rem solid #2571cb;
  outline-offset: 1px;
  outline-offset: 0.1rem;
}
.button {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  padding: 0 16px;
  height: 48px;
  position: relative;
  min-width: 90px;
  background: #e61761;
  color: #fff;
  line-height: 48px;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
}
.button:disabled {
  cursor: default;
}
.button:global(.disabled) {
  background: #757575 !important;
}
.button:focus,
.button:hover {
  background: #c31654;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
  text-decoration: none;
}
.button:active {
  background: #9b1243;
}
@media only screen and (max-width: 768px) {
  .button {
    min-width: 0;
  }
}
@media only screen and (max-width: 544px) {
  .button {
    font-weight: 400;
  }
}
.secondary-button {
  background: #fff;
  border: 1px solid #e61761;
  color: #e61761;
}
.secondary-button:focus,
.secondary-button:hover {
  color: #c31654;
  background: #fce7ef;
}
.secondary-button:active {
  background: #e61761;
  color: #fff;
}
.cancel-button {
  background: #ddd;
  color: #222b56;
}
.cancel-button:active,
.cancel-button:focus,
.cancel-button:hover {
  background: #eee;
}
.cancel-button-borderless {
  color: #757575;
  background: none;
  border: none;
}
.cancel-button-borderless:active,
.cancel-button-borderless:focus,
.cancel-button-borderless:hover {
  background: none;
}
.danger-button {
}
.danger-button,
.danger-button:focus,
.danger-button:hover {
  background: #d8003b;
}
.gray-outline-button {
  border: 1px solid #757575;
  background: #fff;
  color: #757575;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.gray-outline-button:focus,
.gray-outline-button:hover {
  color: #222b56;
  background: #fff;
  border: 1px solid #222b56;
}
.button_link {
  margin: 0;
  display: inline;
  background: none;
  color: #e61761;
}
.button_link:focus {
  text-decoration: underline;
}
.transparent-button {
  border: 0;
  background: none;
}
.transparent-button:focus,
.transparent-button:hover {
  color: #c31654;
  background: none;
}
.transparent-button:active {
  color: #e61761;
  background: none;
}
.register {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  margin: 0 16px;
  padding: 0 16px;
  height: 48px;
  position: relative;
  min-width: 90px;
  background: #e61761;
  color: #fff;
  line-height: 48px;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
}
.button:disabled {
  cursor: default;
}
.button:global(.disabled) {
  background: #757575 !important;
}
.button:focus,
.button:hover {
  background: #c31654;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
  text-decoration: none;
}
.button:active {
  background: #9b1243;
}
@media only screen and (max-width: 768px) {
  .button {
    min-width: 0;
  }
  .button {
    border-radius: 48px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: auto;
    padding: 12px;
  }
  .secondary-button {
    background: #fff;
    color: #e61761;
    border: 2px solid #e61761;
  }
}
@media only screen and (max-width: 544px) {
  .button {
    font-weight: 400;
  }
}
