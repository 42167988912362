.backdrop {
  position: fixed;
  z-index: 1200;
  height: 150vh;
  top: -20%;
  width: 110%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}
.register {
  background-color: white;
  width: 584px;
  max-width: calc(100vw - 20px);
  margin: 10px auto;
  padding: 32px 30px;
  position: fixed;
  z-index: 1205;
  top: 50%;
  left: 0;
  right: 0;
  border-radius: 0px !important;
  transform: translateY(-50%);
}

.divider {
  display: inline-block;
  width: 100%;
  height: 1px;
  margin: 0;
  margin-top: -50px;
  background: #ddd;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
}
.field {
  margin: -20px 0 16px 0px;
  line-height: 2rem;
  padding: 8px 0;
  position: relative;
}
.halfFields {
  width: 47%;
  margin-right: 0;
  line-height: 2rem;
  padding: 10px 0 0 0;
}
.halfFields :global(span) {
  line-height: 1;
  padding-top: 5px;
}
.right {
  margin-right: 0;
}
.select {
  width: 100%;
  padding: 0px 14px;
  border: 1px solid #757575;
  border-radius: 1px;
  box-sizing: border-box;
  background: #fff;
  color: grey;
  line-height: 1.7rem;
}
.select > option {
  background: #fff;
  color: grey;
  padding: 0;
}

.tc_input {
  height: 20px !important;
  width: 20px !important;
  outline: none !important;
}
.tc_label {
  padding-left: 1rem;
  width: 100%;
}
.tc_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}
.error {
  display: inline-block;
  width: 100%;
  padding: 2px;
  background: #d8003b;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}

.update_results {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  width: 40%;
  margin: 3rem auto;
  padding: 1.5rem;
  position: absolute;
  z-index: 1205;
  left: 0;
  right: 0;
  border-radius: 0px !important;
}
.title {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 20px;
}
.message {
  text-align: center;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 2.5rem;
}

.modal_buttons {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_buttons .button {
  font-size: 16px;
  background-color: #c31754;
}

.radioGroup :global(.MuiTypography-body1) {
  font-size: 16px;
}

.phiForm :global(.MuiSvgIcon-root) {
  width: 24px;
  height: 24px;
}

.phiForm :global(.MuiCheckbox-root) {
  padding: 0px;
  flex: none;
  width: 24px !important;
  height: 24px !important;
}

.phiForm :global(input[type='checkbox']) {
  width: 100% !important;
  flex: none;
}

.phiForm :global(input[type='checkbox']) {
  width: 100% !important;
  flex: none;
}

.phiForm :global(h3) {
  padding: 10px 32px;
}

.phiForm :global(*) {
  font-family: 'Poppins' !important, sans-serif;
}

.phiForm :global(.MuiCardContent-root) {
  padding-top: 6px;
}

.phiForm :global(.date-field) {
  line-height: 1.2;
}

.phiForm :global(.MuiFormControlLabel-root:hover) {
  background-color: rgba(0, 0, 0, 0.04);
}

.coreLogo {
  height: 27px;
  background: url('../../Assets/core-logo.svg') center no-repeat;
  background-size: contain;
}

.moveLogo {
  height: 27px;
  background: url('../../Assets/move-logo.svg') center no-repeat;
  background-size: contain;
}

.title {
  padding: 16px 24px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #222b56;
  margin-top: 15px;
}

.content {
  padding: 0px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #222b56;
  margin-top: 23px;
}

.content :global(.MuiTypography-body1) {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #222b56;
}

.formItem {
  height: 53px;
  box-shadow: 0px 0px 8px rgba(34, 43, 86, 0.2);
  background: #ffffff;
  border-radius: 4px;
  width: calc(50% - 8px);
  margin: 0px;
}

.phiPhone :global(input) {
  border: none;
  outline: none !important;
  height: 53px;
}

.phiForm :global(.MuiFormControl-root.MuiTextField-root) {
  padding: 7px 16px 6px 16px;
}

.phiForm :global(label + .MuiInput-formControl) {
  margin-top: 0px;
}

.phiForm :global(.MuiFormLabel-root) {
  top: -4px;
  left: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-transform: capitalize;
  color: rgba(34, 43, 86, 0.5);
}

.phiForm :global(.MuiInputBase-root.MuiFilledInput-root) {
  background: none;
}

.phiForm :global(.MuiInputBase-root input.MuiFilledInput-input) {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  padding: 0px;
}

.phiForm :global(.MuiFormHelperText-root) {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: #ff5252 !important;
  margin: 55px 0px 0px -10px;
  position: absolute;
}

.phiFormContent {
  margin-top: 10px;
  margin-bottom: 8px;
}

.formError {
  margin-bottom: 25px !important;
}

.footerSeparator {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin-top: 24px;
  line-height: 1.5;
  color: #757575;
}

.anyQuestion {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #222b56;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
}

.anyQuestion a {
  font-size: 12px;
}

.questionIcon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  background: url('../../Assets/icon-question.svg') center no-repeat;
  background-size: contain;
}

.phiSelect {
  margin: 0px !important;
  padding: 0px 16px;
}

.phiSelect :global(select) {
  margin: 0px !important;
  padding: 0px !important;
  outline: none !important;
  border: none !important;
  height: 100%;
  border-radius: 4px;
}

.phiPhone :global(.error-label) {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: #ff5252 !important;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .register {
    padding: 0px;
  }

  .phiForm :global(.MuiCard-root) {
    padding: 37px 16px;
    max-height: 100vh;
    max-width: 100vw;
    margin: 0px;
    overflow-y: auto;
  }

  .nameContainer {
    flex-flow: row wrap;
    margin-top: 0px;
  }

  .formItem {
    width: 100%;
    margin-top: 16px !important;
  }

  .anyQuestion {
    align-items: center;
    display: flex;
    flex-flow: column;
  }

  .content,
  .content :global(.MuiTypography-body1),
  .tc_label,
  .anyQuestion,
  .anyQuestion a {
    font-size: 12px;
  }
}
