.container{display:inline-block;line-height:80px;line-height:8rem;position:relative;margin:0 12px;margin:0 1.2rem}
@media (max-width:calc(992px - 1px)){.container{line-height:4rem;margin:0 .8rem}}
.dropdownLabel{line-height:21px;line-height:2.1rem;-webkit-transition:background-color .5s;transition:background-color .5s;padding:0}
.hasUnread{background-color:#e61761;border-radius:.3rem;padding:8px;padding:.8rem}
.dropdownHeader{padding:8px 16px;padding:.8rem 1.6rem;border-bottom:1px solid rgba(0,0,0,.2)}
.dropdownHeader h2{margin:0;line-height:20px;line-height:2rem;font-size:13px;font-size:1.3rem}
.dropdown{background:#fff;min-width:290px;padding:1px 0;color:#222b56;border:1px solid rgba(0,0,0,.2);-webkit-box-shadow:0 0 1px rgba(0,0,0,.1);box-shadow:0 0 1px rgba(0,0,0,.1)}
@media(max-width:544px){.dropdown{min-width:auto;max-width:22rem}}@media(max-width:320px){.dropdown{max-width:21rem}}
.indicator{overflow:visible;border-bottom-color:rgba(0,0,0,.25);top:-1px}
.indicator:after{content:"";position:absolute;z-index:1;width:0;height:0;left:-10px;top:1px;border-left:10px solid transparent;border-right:10px solid transparent;border-bottom:10px solid #fff}
.icon{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.icon span{color:#fff}
.alarmIcon{width:24px;height:24px;color:#222b56;margin-right:0;position:relative;-webkit-transition:color .5s;transition:color .5s}
.alarmIconUnread{color:#fff}.empty{text-align:center;padding:16px;color:#222b56;font-size:16px;font-size:1.6rem;line-height:24px;line-height:2.4rem;font-weight:300}
.empty h4{margin:0}
.empty p{margin-top:0;font-weight:300}
.empty .checkIcon{width:32px;height:32px;color:#e61761;display:inline-block}
.list{max-height:300px;max-height:30rem;position:relative;overflow:auto}
@media (min-width:992px){.list{max-height:40rem}}
.viewAll{text-align:center;font-size:13px;font-size:1.3rem;color:#222b56;line-height:14px;line-height:1.4rem;display:block;padding:10px;font-weight:300;border-top:1px solid rgba(0,0,0,.2)}
.viewAll:focus,.viewAll:hover{color:#e61761}