.container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 250px;
  width: 250px;
  background-color: #fff;
}
@media (max-width: 645px) {
  .container {
    border: 0.1rem solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.container:focus {
  text-decoration: none;
}
.infoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding: 1.6rem;
  overflow: hidden;
}
.title {
  margin: 0 0 8px;
  margin: 0 0 0.8rem;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  color: #222b56;
  line-height: 22px;
  line-height: 2.2rem;
  max-height: 44px;
  max-height: 4.4rem;
  overflow: hidden;
}
.subtitle {
  color: #757575;
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
  padding-top: 10px;
  margin: 2px 0px;
  line-height: 1.6rem;
  max-height: 32px;
  max-height: 3.2rem;
}
.distance,
.subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.distance {
  margin-top: auto;
}
.marker {
  left: -5px;
  left: -0.5rem;
  margin-right: 0;
}
.favorite {
  top: 10px;
  top: 1rem;
  right: 10px;
  right: 1rem;
  background-color: rgba(34, 43, 86, 0.6);
  border-radius: 2rem;
  height: 30px;
  height: 3rem;
  width: 30px;
  width: 3rem;
}
.favorite,
.favorited {
  display: inline-block;
  vertical-align: middle;
}
.favorited {
  height: 20px;
  height: 2rem;
  width: 20px;
  width: 2rem;
  stroke: #fff;
  stroke-dasharray: 55;
  fill: #d8003b;
  stroke-width: 2;
}
.empty {
  stroke: #fff;
  fill: transparent;
}
.updatingFavorite {
  -webkit-animation: updating 2.5s linear forwards infinite;
  animation: updating 2.5s linear forwards infinite;
}
@-webkit-keyframes updating {
  0% {
    stroke-dashoffset: 55;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes updating {
  0% {
    stroke-dashoffset: 55;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.scheduleInfo {
    color: #840090;
    font-weight: 900;
    font-size: 14px;
    font-family: Poppins, Helvetica, Arial, sans-serif;
    line-height: 16px;
    line-height: 1.6rem;
    max-height: 32px;
    max-height: 3.2rem;
    
}

.duration {
    position: absolute;
    font-size: 14px;
    top: 15px;
    right: 10px;
    text-align: right;
}

.buttonContainer {
    position: absolute;
    bottom: 15px;
    left: 25px;
    right: 10px;
}

.buttonContainer button {
    height: 25px;
    padding: 20px 0px;
    color: white;
    display: inline-flex;
    font-family: Poppins, Helvetica, Arial, sans-serif;
    justify-content: center; /* center the content horizontally */
    align-items: center; /* center the content vertically */
    width: 200px;
    border-radius: 100px;
    background:
          linear-gradient(90deg, rgba(132,0,144,1) 0%, rgba(230,23,97,1) 100%);
}

.buttonContainer button:disabled {
    height: 25px;
    padding: 20px 0px;
    color: white;
    display: inline-flex;
    font-family: Poppins, Helvetica, Arial, sans-serif;
    justify-content: center; /* center the content horizontally */
    align-items: center; /* center the content vertically */
    width: 200px;
    border-radius: 100px;
    background: lightgrey
}