.memberId {
  color: #343434;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.memberId span:first-child {
  white-space: nowrap;
  font-weight: 300;
  margin-right: 8px;
  margin-right: 0.8rem;
  display: inline-block;
}
.memberId span:last-child {
  white-space: nowrap;
  font-weight: 600;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
}
