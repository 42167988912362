.container {
  position: fixed;
  height: 56px;
  height: 5.6rem;
  width: 100%;
  padding: 0 8px;
  padding: 0 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #222b56;
  z-index: 1150;
}
@media (max-width: 768px) {
  .container {
    height: 7.6rem;
  }
}
@media (max-width: 544px) {
  .container {
    height: 9.1rem;
  }
}
.copy {
  margin: 0;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 24px;
  line-height: 2.4rem;
  text-align: center;
  color: #fff;
  display: inline;
}
@media (max-width: 360px) {
  .copy {
    font-size: 1.4rem;
  }
}
.link {
  color: #fff;
  display: inline;
  text-decoration: underline;
}
