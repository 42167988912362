.container {
  top: 0;
  height: 65px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e61761;
  z-index: 2000;
}
@media (max-width: 768px) {
  .container {
    height: 7.6rem;
  }
}
@media (max-width: 544px) {
  .container {
    height: 9.1rem;
  }
}
.copy {
  margin: 0;
  font-size: 20px;
  font-size: 2rem;
  line-height: 24px;
  line-height: 2.4rem;
  text-align: center;
  justify-content: center;
  color: #fff;
  display: inline;
}

.copy a {
  font-size: 20px;
  font-size: 2rem;
  color: #e61761;
  text-decoration: none;
}

.copy button {
  border-radius: 4px;
  background-color: white;
  width: 150px;
  height: 30px;
  margin: 0px 10px;
}

.copy img {
  height: 20px;
  width: auto;
}
