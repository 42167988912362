:root {
  --swiper-theme-color: #d91e61;
}

body {
  height: 100vh;
  background-color: white;
}
.peerfit-fiton-integration-container {
  height: 100vh;
  padding-bottom: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.peerfit-fiton-integration-container .fiton-icon {
  width: 205px;
  margin-bottom: 38px;
}
.peerfit-fiton-integration-container .fiton-concept-text {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 64px;
  width: 639px;
  margin-bottom: 41px;
  color: #333;
  line-height: 120%;
}
.peerfit-fiton-integration-container .peerfit-concept-text {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #333;
  width: 378px;
  margin-bottom: 50px;
  line-height: 140%;
}
.peerfit-fiton-integration-container .start-button,
.peerfit-fiton-integration-container .start-button-mobile {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d91e61;
  border-radius: 30px;
  width: 296px;
  height: 56px;
  color: white;
}
.peerfit-fiton-integration-container .start-button-mobile {
  display: none;
  width: 234px;
  margin-top: 22px;
}
.peerfit-fiton-integration-container .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.peerfit-fiton-integration-container .right-part {
  width: 482px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.peerfit-fiton-integration-container .gradient-background {
  position: absolute;
  width: 28vw;
  height: 100vh;
  right: 0;
  top: 0;
  background: linear-gradient(180.05deg, #162879 9.12%, #202141 97%);
  z-index: -1;
}
.peerfit-fiton-integration-container .close-icon,
.peerfit-fiton-integration-container .close-icon-black {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.peerfit-fiton-integration-container .close-icon-black {
  visibility: hidden;
  top: 22px;
  right: 22px;
}

.carousel-slider {
  width: 482px;
  position: relative;
}

.carousel-slider-animate-opacity img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.swiper-slide {
  width: 482px;
  height: 482px;
}

.carousel-slider-animate-opacity {
  height: 100%;
}

.slide-content {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  bottom: 0;
  padding: 24px;
  box-sizing: border-box;
  background: linear-gradient(
    180.05deg,
    rgba(22, 40, 121, 0) 9.12%,
    rgba(32, 33, 65, 0.9) 97%
  );
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: flex-end;
}

.slide-content h2 {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  margin: 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 44.8px;
  color: white;
}

.swiper-pagination-bullet-active {
  width: 24px;
  border-radius: 4px;
}

.swiper-pagination {
  bottom: -30px !important;
}

.swiper-button-next {
  height: 40px;
  width: 40px;
  right: -20px;
}

.swiper-button-prev {
  height: 40px;
  width: 40px;
  left: -20px;
}

.swiper-button-next:after {
  content: none;
}
.swiper-button-prev:after {
  content: none;
}

.swiper-pagination {
  text-align: left;
}

@media screen and (max-width: 1080px) {
  .peerfit-fiton-integration-container .gradient-background {
    position: absolute;
    width: 100vw;
    height: 46vh;
    right: 0;
    left: 0;
    top: 54vh;
    bottom: 0;
  }
  .peerfit-fiton-integration-container .close-icon {
    visibility: hidden;
  }
  .peerfit-fiton-integration-container .close-icon-black {
    visibility: visible;
  }
  .peerfit-fiton-integration-container .content-box {
    flex-direction: column;
  }
  .peerfit-fiton-integration-container .fiton-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 108px;
  }
  .peerfit-fiton-integration-container .fiton-concept-text {
    width: 100%;
    font-size: 8vw;
    margin-bottom: 16px;
  }
  .peerfit-fiton-integration-container .peerfit-concept-text {
    width: 333px;
    font-size: 18px;
    margin-bottom: 24px;
  }

  .peerfit-fiton-integration-container .right-part {
    width: 100vw;
    height: auto;
    margin-bottom: 20px;
  }
  .peerfit-fiton-integration-container .start-button {
    display: none;
  }
  .peerfit-fiton-integration-container .start-button-mobile {
    display: flex;
  }

  .carousel-slider {
    width: 100%;
  }

  .swiper-slide {
    width: 260px;
    height: 260px;
  }

  .swiper-pagination {
    text-align: center;
    bottom: -23px !important;
  }
  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    background-color: #cccccc;
    opacity: 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .slide-content {
    padding: 16px;
  }
  .slide-content h2 {
    font-size: 18px;
    line-height: 1.4;
  }
}
