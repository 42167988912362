.login-page {
  height: 100vh;
  width: 100vw;
  background: #edf1f7;
  position: relative;

  .login-logo {
    width: 160px;
  }

  .login-page-header {
    padding: 27px 33px;
    width: 100%;
    position: absolute;
  }

  .login-page-main {
    height: 100%;
    width: 100%;
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-btn {
    display: block;
    width: 100%;
    margin-top: 24px;
  }
}

.btn-default.outline {
  outline: 3px solid #2571cb;
  outline: 0.3rem solid #2571cb;
  outline-offset: 1px;
  outline-offset: 0.1rem;
}
.btn-default {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  padding: 0 16px;
  height: 48px;
  position: relative;
  min-width: 90px;
  color: #fff;
  line-height: 48px;
  font-size: 16px;
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
  background: #d91e61;
  border-radius: 4px;
}
.btn-default:disabled {
  cursor: default;
}
.btn-default.disabled {
  background-color: #757575 !important;
}
.btn-default:focus,
.btn-default:hover {
  background: #c31654;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
  text-decoration: none;
}
.btn-default:active {
  background: #9b1243;
}
@media only screen and (max-width: 768px) {
  .btn-default {
    min-width: 0;
    font-size: 18px;
  }
  .login-page {
    .login-page-header {
      padding: 46px 19px 12px;
      background-color: #ffffff;
      border-bottom: 1px solid #f0f0f0;
    }
    .login-logo {
      height: 27px;
    }
    .login-page-main {
      display: block;
      background-color: #ffffff;
    }
    .login-page-form-wrapper {
      padding-top: 70px;
      .title {
        margin-top: 35px;
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 1.17;
      }
      .login-form .copy {
        font-size: 16px;
        line-height: 22px;
      }
      .login-form .forget-password {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
@media only screen and (max-width: 544px) {
  .btn-default {
    font-weight: 400;
  }
}
