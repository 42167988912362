.container{text-align:center;position:relative}.navContainer{position:relative;padding-top:32px;padding-top:3.2rem}
.nav{position:relative;width:1000px;max-width:100%}.link,.nav{display:inline-block}
.link{line-height:40px;line-height:4rem;vertical-align:top;padding:16px 8px;color:#222b56;width:50%}
@media(max-width:768px){.link{min-width:130px}}@media(max-width:544px){.link{width:50%}}

.indicator{height:4px;/*position:absolute;*/width:100px;bottom:0;left:0;background:#e61761;-webkit-transition:.2s ease-in-out;transition:.2s ease-in-out;display:none}
@media(max-width:768px){.indicator{display:block}}

.icon{vertical-align:middle;display:inline-block;height:48px;width:48px;margin-right:8px}
@media(max-width:544px){.icon{margin-right:0}}.people{fill:#e61761}
.briefcase{fill:#6ac1ff}.text{display:block;margin:0}
@media(max-width:768px){.text{font-size:1.8rem;line-height:2.4rem}}
@media(max-width:544px){.text{font-size:2rem;line-height:2.8rem}}
.content{max-width:1100px;margin:0 auto;text-align:left}
.content h4{margin-bottom:0}
.tab{width:50%;display:inline-block;vertical-align:top;padding:0 64px;padding:0 6.4rem;position:relative}
@media(max-width:544px){.tab{padding:1.6rem 3.2rem}}@media(max-width:768px){.tab{width:100%}}
.subscriptionContent{}
.subscriptionContent:after{content:"";position:absolute;right:-1px;bottom:0;top:-64px;width:1px;background:#ddd}
@media(max-width:768px){.subscriptionContent:after{display:none}}
.corporateContent{}.ctaBlock{text-align:center;padding:16px;padding:1.6rem}
.cta{margin:32px 0;margin:3.2rem 0}
@media(max-width:544px){.cta{margin-top:0}}