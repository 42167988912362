.reserve {
  margin: 0;
}
.classRow {
  height: 60px;
  height: 6rem;
  background: #fff;
}
.classRow .col:first-child {
  padding-left: 8px;
}
.classRow .col:last-child {
  padding-right: 8px;
}
.classBlock {
  padding: 16px;
  overflow: hidden;
}
.classBlock,
.col {
  border-bottom: 1px solid #eee;
  font-size: 13px;
  font-size: 1.3rem;
}
.col {
  padding-left: 10px;
  padding-right: 10px;
}
.mobileCol {
  width: 85px;
  width: 8.5rem;
  float: left;
}
.mobileCol:last-child {
  width: auto;
  float: none;
  padding-left: 8px;
  padding-left: 0.8rem;
  overflow: hidden;
}
.mobileLine {
  margin: 10px 0;
  margin: 1rem 0;
}
.mobileLine:nth-child(2) {
  color: #757575;
}
.mobileRow {
  clear: both;
}
.creditsBlock {
  padding-top: 14px;
  padding-top: 1.4rem;
}
.timeCol,
.titleCol {
}
.titleCol {
  width: 150px;
  color: #e61761;
}
.reserveCol {
  padding-right: 8px;
  width: 130px;
  text-align: center;
}
.title_class {
  font-weight: 600;
}
.studioLink {
  text-align: left;
  margin: 0;
  width: 90px;
  width: 9rem;
  font-size: 14px;
  font-size: 1.4rem;
  word-wrap: break-word;
}
@media (max-width: calc(768px - 1px)) {
  .studioLink {
    width: auto;
  }
}
