@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.spinner {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  top: 50%;
  left: 50%;
  border: 3px solid #ff7caa;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 1;
}
.newSpinner {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  top: 50%;
  left: 50%;
  z-index: 1;
  background: url('../Assets/spinner.svg') center no-repeat;
  background-size: 150%;
  border: none;
}
.placeholderContainer {
  height: 100%;
  width: 100%;
  position: relative;
}
