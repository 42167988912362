.inputContainer {
}
.button {
  margin: 0;
}
.label {
}
.container {
}
.thumb {
  position: relative;
  margin: 0 40px 0 0;
  float: left;
  overflow: hidden;
  background: #eee;
  color: #fff;
}
.thumb,
.thumb:after {
  width: 140px;
  height: 140px;
}
.thumb:after {
  content: '';
  position: absolute;
  top: -475px;
  left: -475px;
  border-radius: 50%;
  border: 475px solid hsla(0, 0%, 100%, 0.5);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 4;
}
@media (max-width: 544px) {
  .thumb {
    margin: 0 20px 20px 0px;
  }
}
.thumbEmpty {
  cursor: pointer;
  color: #ddd;
}
.nail {
  width: 100%;
  height: 100%;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.del,
.nail {
  position: absolute;
  top: 0;
}
.del {
  width: 30px;
  height: 30px;
  padding: 7px;
  right: 0;
  background: #ddd;
  z-index: 5;
  cursor: pointer;
}
.camera {
  width: 50px;
  height: 50px;
  margin: 55px;
}
.trashcan {
  width: 100%;
  height: 100%;
}
