.scheduleErrorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 86px 182px;
  width: 100%;
}

.scheduleErrorContainer p {
  text-align: center;
  width: 60%;
  min-width: 460px;
  font-size: 18px;
  font-weight: 400;
  color: #878686;
}
