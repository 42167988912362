.container {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.signOutButton,
.signOutButton:focus,
.signOutButton:hover {
  background: #fff;
  color: #757575;
  border: 2px solid #757575;
}
