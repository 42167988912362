.container {
  padding-top: 32px;
  padding-bottom: 32px;
}
.icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
@media (max-width: 544px) {
  .icon {
    display: none;
  }
}
.sync {
  display: inline-block;
  line-height: 30px;
  line-height: 3rem;
  float: right;
}
.sync svg {
  top: 3px;
}
@media (max-width: 544px) {
  .sync {
    line-height: 4rem;
  }
}
.filterButton {
  display: inline-block;
  border-radius: 1px;
  padding: 8px 16px 7px;
  border: 1px solid #757575;
  color: #757575;
  background: #fff;
  font-size: 14px;
  margin-right: 8px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.filterButton:focus,
.filterButton:hover {
  color: #222b56;
  border-color: #222b56;
  text-decoration: none;
}
.filterButtonActive {
  padding: 7px 15px 6px;
  border: 2px solid #2b4596;
  background: #fff;
  color: #222b56;
}
.dropdown {
  display: inline-block;
}
.dropdownButton {
  border: 1px solid #ddd;
  background: #fff;
  padding: 7px 20px;
}
.dropdownContent {
  top: 50px;
}
.dropdownItem {
}
.dropdownItemActive {
}
@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}
