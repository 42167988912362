.mobile-nav {
  height: 72px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #fff;
  border-top: 1px solid #e5e5e5;
  z-index: 100;
}

.mobile-nav svg {
  fill: none;
}

.mobile-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  padding: 14px 0;
  border-top: 2px solid transparent;
}

.mobile-nav-item-active {
  border-top: 2px solid #E61761;
}
