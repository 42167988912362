@media (max-width: 768px) {
  .pageContainer {
    position: fixed;
    z-index: 999;
    height: 100vh;
    width: 100vw;
    display: block;
    top: 0px;
    background: white;
  }
  .pageHeader {
    height: 60px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    padding: 16px 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e5e5e5;
  }

  .backBtn {
    height: 32px;
    width: 32px;
    padding: 8px;
    color: transparent;
    position: absolute;
    left: 13px;
  }

  .container {
    height: calc(100% - 60px);
    overflow-y: auto;
  }

  .fullHeight {
    height: 100%;
  }
}
