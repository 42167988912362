.page {
  border-top: 1px solid #eee;
}
.page-container {
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .page-container {
    padding: 0 30px;
  }
}
@media (max-width: 320px) {
  .page-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.comma-del:last-child:after {
  content: '';
}
.comma-del:after {
  content: ',\A0';
}
.hide-offscreen {
  width: 0;
  height: 0;
  overflow: hidden;
}
.hide-offscreen * {
  position: absolute;
  width: 1px;
  height: 1px;
  left: -10000px;
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:after,
.clear-fix:before {
  content: ' ';
  display: table;
}
.clear-fix:after {
  clear: both;
}
.tint:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.4))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
}
.tint .container {
  position: relative;
  z-index: 2;
}
.mobileOnly {
  display: none !important;
}
@media (max-width: 768px) {
  .mobileOnly {
    display: block !important;
  }
  .desktopOnly {
    display: none !important;
  }
}
.fullHeight {
  height: 100%;
}
