.moveFooter {
  background: white;
  color: #222b56;
  width: 85%;
  margin: 0 auto;
}

.footer-logo {
  margin-top: 2.2rem;
}

.img-fluid {
  width: 200px;
  height: auto;
}
.section-title {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #14144b;
  margin-bottom: 10px;
}

.link {
  font-size: 18px;
  line-height: 2;
  color: #2c2c5d;
}

.link span:hover {
  color: #e92f71;
  text-decoration: underline;
}
.inner {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.row {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.col {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 22px;
}

.facebook-container {
  width: 220px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 30rem;
  bottom: 0;
  left: 0;
}
.fb-text {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 2;
  color: #2c2c5d;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #2c2c5d;
  border-radius: 50%;
  padding: 6px;
  margin-left: 5px;
}

.icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
  color: #2c2c5d;
}
.icon:hover {
  color: #e92f71;
}
.contact-us {
  border: 2px solid #e92f71;
  border-radius: 50px;
  color: #e92f71;
  padding: 1rem 2rem;
  margin-left: -10px;
  margin-top: 30px;
}
.contact-us:hover {
  border: 2px solid #e92f71;
  border-radius: 50px;
  background-color: #e92f71;
  color: white;
  padding: 1rem 2rem;
}

.mobile-contact {
  display: none;
}

.trademark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  margin: 80px auto 30px auto;
}
.copyright {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.disclaimer-text {
  color: #757575;
}
.copyright-link {
  font-size: 12px;
  color: #007bff;
}

@media (max-width: 768px) {
  .inner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .facebook-container {
    display: none;
  }
  .contact-us {
    display: none;
  }
  .mobile-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .facebook-container-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .contact-us-mobile {
    border: 2px solid #e92f71;
    border-radius: 50px;
    color: #e92f71;
    padding: 1rem 2rem;
    margin-left: -10px;
    margin-top: 30px;
  }
  .contact-us-mobile:hover {
    border: 2px solid #e92f71;
    border-radius: 50px;
    background-color: #e92f71;
    color: white;
    padding: 1rem 2rem;
    margin-right: -20px;
  }
}

.moveFooter span {
  color: #222b56;
}
