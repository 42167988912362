
/* FeaturedStudios styles */
.container {
    padding: 16px;
    padding: 1.6rem;
    background-color: #edf4fd;
  }
  .loadingContainer {
  }
  .inner {
    max-width: 1200px;
    max-width: 120rem;
    margin: 0 auto;
    position: relative;
  }
  .subtitleRow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .subtitleRow,
  .title {
    margin-bottom: 16px;
    margin-bottom: 1.6rem;
  }
  .title {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 32px;
    line-height: 3.2rem;
  }
  .description {
    display: inline-block;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 24px;
    line-height: 2.4rem;
    margin-right: 48px;
    margin-right: 4.8rem;
    max-width: 80%;
    color: #343434;
  }
  .link {
    display: none;
  }
  @media (min-width: 360px) {
    .link {
      display: inline-block;
      text-align: right;
      min-width: 7rem;
      font-weight: 600;
      position: absolute;
      top: 0.6rem;
      right: 0;
    }
  }
  .studiolist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .studiolistCard {
    width: 100%;
    margin-bottom: 16px;
    margin-bottom: 1.6rem;
  }
  @media (min-width: 544px) {
    .container {
      background-color: transparent;
      padding: 3.2rem;
    }
  }
  @media (min-width: 768px) {
    .link {
      position: static;
    }
    .description {
      max-width: none;
    }
    .subtitleRow {
      margin-right: 1.6rem;
    }
    .studiolist {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      max-width: 100%;
    }
    .studiolistCard {
      margin-right: 1.6rem;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 47%;
      flex: 1 0 47%;
    }
  }
  @media (min-width: 992px) {
    .subtitleRow {
      margin-right: 1.6rem;
    }
    .studiolistCard {
      -webkit-box-flex: unset;
      -ms-flex: unset;
      flex: unset;
      max-width: 28.4rem;
    }
  }
  