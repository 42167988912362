.container {
  text-align: left;
}
.label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6f6f6f;
  margin-bottom: 8px;
  padding: 0 16px;
}
.list {
}
.item {
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
}
.item:first-of-type {
  border-top: 1px solid #E5E5E5;
}
.icon {
  margin-right: 5px;
  color: black;
}
.title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
}
.divider {
  background-color: #E5E5E5;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0 5px;
}
.address {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
}
