.container {
  border: 1px solid #ddd;
  clear: both;
}
.strip {
  height: 60px;
}
.dateSelector {
  position: relative;
  padding: 0 49px;
  background: #edf4fd;
  color: #222b56;
  text-align: center;
}
.mblogo {
  display: block;
  width: 150px;
  margin: 30px auto;
}
.dateOption {
  display: inline-block;
  min-width: 16.667%;
  font-size: 16px;
  font-size: 1.5rem;
  padding: 0 4px;
  cursor: pointer;
  line-height: 60px;
  line-height: 6rem;
  height: 60px;
}
.dateOption:focus span {
  outline: 3px solid #2571cb;
  outline: 0.3rem solid #2571cb;
  outline-offset: 1px;
  outline-offset: 0.1rem;
  -webkit-transition: outline-width 0.2s;
  transition: outline-width 0.2s;
}
:global(.mouse_or_touch_user) .dateOption:focus span {
  outline: none;
}
.dateOption:hover {
  border-bottom: 4px solid #eee;
}
@media (max-width: 768px) {
  .dateOption {
    width: auto;
    cursor: inherit;
  }
}
.currentDateOption {
  color: #2b4596;
  font-weight: 600;
}
.currentDateOption,
.currentDateOption:focus,
.currentDateOption:hover {
  border-bottom: 4px solid #2b4596;
}
.datesContainer {
  display: block;
}
.arrowContainer {
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  color: #222b56;
}
.arrowContainer:focus .arrow {
  outline: 3px solid #2571cb;
  outline: 0.3rem solid #2571cb;
  outline-offset: 1px;
  outline-offset: 0.1rem;
  -webkit-transition: outline-width 0.2s;
  transition: outline-width 0.2s;
}
:global(.mouse_or_touch_user) .arrowContainer:focus .arrow {
  outline: none;
}
@media (max-width: 768px) {
  .arrowContainer {
    width: 25%;
  }
}
.arrowContainerLeft {
  left: 0;
}
.arrowContainerRight {
  right: 0;
}
.arrow {
  cursor: pointer;
  height: 24px;
  height: 2.4rem;
  margin: 18px 8px;
  margin: 1.8rem 0.8rem;
}
