.container {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.content {
}
@media (max-width: 544px) {
  .content {
    padding: 0;
  }
}
.tabs {
  padding: 0;
  margin: 0;
}
.navItem,
.tabs {
  display: inline-block;
}
.navItem {
  margin-right: 32px;
}
.link {
  position: relative;
  color: #757575;
  display: inline-block;
  line-height: 46px;
  line-height: 4.6rem;
  font-size: 15px;
  font-size: 1.5rem;
  text-decoration: none;
}
.activeLink,
.link:focus,
.link:hover {
  color: #2b4596;
}
.activeLink {
  font-weight: 600;
  line-height: 42px;
  line-height: 4.2rem;
  border-bottom: 4px solid #2b4596;
}
.accountInfo {
  line-height: 50px;
  line-height: 5rem;
  font-size: 16px;
  font-size: 1.6rem;
  float: right;
}
@media (max-width: 768px) {
  .accountInfo {
    display: none;
  }

  .container {
    border: none;
  }
  .tabs {
    display: flex;
    flex-flow: column nowrap;
    border-bottom: 1px solid #e5e5e5;
  }
  .navItem {
    border-top: 1px solid #e5e5e5;
    margin-right: 0px;
    padding: 0px 17px;
  }
  .navItem a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 44px;
    text-transform: capitalize;
    color: #2b4596;
    width: 100%;
  }

  .mobilePage {
    display: none;
  }
}
.employer {
  max-height: 40px;
  float: right;
  margin: 5px 0 5px 30px;
}
.dropdownLabel {
  text-align: left;
  padding: 15px 20px;
  width: 100%;
}
.dropdownLabel svg {
  position: absolute;
  top: 22px;
  right: 20px;
}
.dropdownIndicator {
  display: none;
}
.dropdownContent {
  background: #fff;
  border-radius: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.dropdownLink {
  display: block;
  padding: 0 20px;
  line-height: 45px;
  line-height: 4.5rem;
  color: #222b56;
}
.dropdownActiveLink {
  background: #2b4596;
  color: #fff;
}
