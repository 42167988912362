.container {
  background: linear-gradient(22.62deg, #222b56 22.04%, #2b4596 94.11%), #222b56;
  background-size: cover;
  background-color: #222b56;
  display: block;
  position: relative;
  height: 130px;
  height: 13rem;
  margin: 10px;
  margin: 1rem;
  color: #fff;
  text-decoration: none !important;
}
.copyContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  height: 130px;
  height: 13rem;
  width: 440px;
  width: 44rem;
  margin-left: 123px;
  margin-left: 12.3rem;
  padding: 14px 0;
  padding: 1.4rem 0;
}
.description,
.title {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 22px;
  line-height: 2.2rem;
  margin: 0;
}
.description {
  font-weight: 300;
}
.icon {
  position: absolute;
  margin: 0 24px 0 17px;
  margin: 0 2.4rem 0 1.7rem;
  top: 19px;
  top: 1.9rem;
  width: 92px;
  width: 9.2rem;
  height: 92px;
  height: 9.2rem;
}
.iconFitOn {
  height: 100%;
  width: 100%;
  margin-left: 3px;
  margin-top: 2px;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.iconContainerFitOn {
  position: absolute;
  margin: 0 24px 0 17px;
  margin: 0 2.4rem 0 1.7rem;
  top: 19px;
  top: 1.9rem;
  width: 92px;
  width: 9.2rem;
  height: 92px;
  height: 9.2rem;
  background-color: #edf4fd;
  border-radius: 50%;
}
@media (max-width: 600px) {
  .copyContainer {
    width: auto;
    margin-right: 0.8rem;
  }
  .icon {
    margin: 0 1.6rem;
    top: 1.9rem;
  }
  .description {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}
@media (max-width: 544px) {
  .container {
    margin: -2rem -2rem 2rem;
    height: 13rem;
    padding: 0 1rem;
  }
}
@media (max-width: 380px) {
  .copyContainer {
    margin-left: 10rem;
  }
  .icon {
    margin: 0;
  }
}
@media (max-width: 320px) {
  .container {
    padding-top: 0.6rem;
  }
}
