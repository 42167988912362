.container {
  position: fixed;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 9999;
  background: white;
}
.container button:active,
.container button:hover,
.container button:focus {
  background: unset;
  border: none;
  outline: none;
}

.close-icon {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 1;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 64px;
}

.header-text {
  text-align: center;
  font-size: 18px;
  height: 100%;
  line-height: 64px;
}

.body {
  padding-top: 64px;
  margin-bottom: 72px;
}

.footer {
  height: 72px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #fff;
  box-shadow: 0px -1px 0px rgba(174, 174, 174, 0.25);
}
