.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1220px;
  max-width: 122rem;
  margin: 0 auto 90px;
  margin: 0 auto 9rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.description {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 450px;
  -ms-flex: 0 0 45rem;
  flex: 0 0 450px;
  flex: 0 0 45rem;
  margin: 0 20px;
  margin: 0 2rem;
}
.description h3 {
  margin: 0 0 8px;
  margin: 0 0 0.8rem;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 32px;
  line-height: 3.2rem;
}
.description h4 {
  margin: 0 0 24px;
  margin: 0 0 2.4rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.description h4,
.description p {
  font-size: 16px;
  font-size: 1.6rem;
  color: #343434;
}
.description ul {
  padding-left: 16px;
  padding-left: 1.6rem;
}
.description ul > li {
  margin-bottom: 8px;
  margin-bottom: 0.8rem;
  list-style: disc;
  font-size: 16px;
  font-size: 1.6rem;
  color: #343434;
}
.videoContainer {
  width: auto;
  height: auto;
  margin: 0 20px;
  margin: 0 2rem;
}
.iconContainer {
  width: 120px;
  width: 12rem;
  height: 120px;
  height: 12rem;
  padding: 36px;
  padding: 3.6rem;
  margin: -60px 0 0 -60px;
  margin: -6rem 0 0 -6rem;
  border-width: 4px;
  border-width: 0.4rem;
}
.iconContainer svg {
  width: 40px;
  width: 4rem;
  height: 40px;
  height: 4rem;
}
.membershipBtn {
  width: 330px;
  width: 33rem;
  margin: 24px 0 0;
  margin: 2.4rem 0 0;
}
.btnDisabled {
  pointer-events: none;
  background-color: darkgray;
}
@media (max-width: 992px) {
  .container {
    display: block;
    padding: 1.6rem;
  }
  .videoContainer {
    max-width: none;
    width: 100%;
    height: auto;
    margin: 0 0 2.4rem;
    float: none;
  }
  .description {
    width: auto;
    padding: 0;
    margin-bottom: 0;
    float: none;
  }
  .membershipBtn {
    display: block;
    margin: 2.4rem auto 0;
  }
}
@media (max-width: 544px) {
  .container {
    padding: 1.6rem;
    margin-bottom: 3.2rem;
  }
  .description {
    margin-bottom: 3rem;
  }
  .iconContainer {
    width: 6rem;
    height: 6rem;
    padding: 1.8rem;
    margin: -3rem 0 0 -3rem;
    border-width: 0.2rem;
  }
  .iconContainer svg {
    width: 2rem;
    height: 2rem;
  }
  .membershipBtn {
    width: 100%;
  }
}
