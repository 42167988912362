.cardInfo{
    padding:16px 32px 16px;
    padding: 1.6rem 3.2rem 1.6rem;
    text-align: left;
}
.cardDetail{
    font-family: Arial, Helvetica, sans-serif;
    font-weight:bold;
    font-size: 15px;
}


.card{
    float:left;
    border:2px solid #ddd;
    text-align:center;
    min-width:250px;
    width:25%;
    margin:0 16px 16px 0;
    margin:0 1.6rem 1.6rem 0;
    color:#2b4596;
    /* cursor:pointer; */
    -webkit-transition:border .2s;
    transition:border .2s;   
}

