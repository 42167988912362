.session-card {
  border-bottom: 1px solid #333;
  display: grid;
  grid-template-columns: 1.5fr 1.25fr 0.25fr;
  padding: 0 0.5rem;
  align-items: center;
  margin: 0;
  position: relative;
}

.session-card :global(p) {
  margin: 0;
}

.session-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 12px 10px 12px 0px;
}

.session-reservation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p.session-name {
  color: #e61761;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 3px;
}
p.session-instructor {
  color: #e61761;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 10px;
}
.session-time {
  font-weight: bold;
  bottom: 0;
  margin: 0;
}
.session-duration {
  font-size: 1.3rem;
  opacity: 0.8;
}
.attendance-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spots-left {
  font-weight: bold;
}
.total-booked {
  font-size: 1.3rem;
  margin-left: 0.1rem;
  opacity: 0.8;
}

.strip {
  text-align: center;
  width: 8rem;
  height: 2rem;
  line-height: 2rem;
  background: #2b4596;
  border-radius: 1.6rem;
  color: white;
  position: relative;
}
.button {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  padding: 0 1rem;
  height: 3.5rem;
  position: relative;
  min-width: 9rem;
  background: #e61761;
  color: #fff;
  line-height: 3.5rem;
  font-size: 1.6rem;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
}
.button:hover {
  top: 0.2rem;
}
@media screen and (max-width: 750px) {
  .session-card {
    grid-template-columns: 1.5fr 2fr;
  }
  .attendance-details-container {
    top: 2rem;
    right: 2.5rem;
    position: relative;
  }
  .strip {
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .button {
    min-width: 8rem;
    position: absolute;
    top: 33%;
    right: 0.5rem;
    bottom: 33%;
  }
}
.button:disabled {
  cursor: default;
}
.button:focus,
.button:hover {
  background: #c31654;
  -webkit-transition: color 0.2s, background 0.2s;
  transition: color 0.2s, background 0.2s;
  text-decoration: none;
}
.button:active {
  background: #9b1243;
}

.mobile-app-session-card {
  display: block;
  padding: 16px 32px;
}

.mobile-app-session-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
}

.mobile-app-attendance-details-container {
  top: unset;
  right: unset;
  width: 100%;
  justify-content: flex-start;
}

.mobile-app-strip {
  position: absolute;
  left: unset;
  right: 0.25rem;
}

.mobile-app-reserve-button {
  position: static;
  width: 100%;
  height: 48px;
  margin: 12px 0;
}
