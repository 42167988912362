.userimg {
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  background: #fff;
}
.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  padding-bottom: 45px;
}
.inner:after {
  content: '';
  display: table;
  clear: both;
}
@media (max-width: 544px) {
  .inner {
    padding-bottom: 0px;
  }
}
.editIcon {
  width: 100%;
  height: 100%;
}
.editBtn {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -25px;
  right: 25px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  z-index: 1;
  padding: 16px;
  color: #fff;
}
.editBtn:focus,
.editBtn:hover {
  background: rgba(0, 0, 0, 0.5);
}
.credits,
.editBtnMobile {
  display: none;
}
@media (max-width: 544px) {
  .editBtn {
    width: 40px;
    height: 40px;
    top: -20px;
    right: 20px;
    padding: 10px;
    display: none;
  }
  .editBtnMobile {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #1f274c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
  }
  .editBtnMobile svg {
    margin-left: -10px;
    margin-right: 10px;
    height: 14px;
    width: 14px;
    color: transparent;
  }

  .credits {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #2b4596;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 14px 16px;
    background: #ebf3fd;
    margin: 0px 16px;
  }
  .credits svg {
    margin-right: 7px;
    height: 17px;
    width: 15px;
    color: transparent;
  }
  .creditsAction {
    position: absolute;
    right: 20px;
    height: 16px;
    width: 16px;
  }
}
.profile {
  position: absolute;
  top: -60px;
  float: left;
  z-index: 1;
  color: #ddd;
  margin: 0 25px 0 15px;
}
@media (max-width: 768px) {
  .profile {
    left: 50%;
    top: 0;
    margin-left: -50px;
    margin-top: -50px;
  }
}
.avatar {
  width: 120px;
  height: 120px;
}
.social {
  position: relative;
  text-align: center;
}
.socialLink {
  margin: 10px;
  width: 20px;
  height: 20px;
  display: inline-block;
  color: #757575;
}
.info {
  overflow: hidden;
  /* margin-left: 150px; */
  min-width: 350px;
  margin: 0 15rem;
}
@media (max-width: 768px) {
  .info {
    clear: both;
    min-width: 320px;
    max-width: 100%;
    padding: 30px 0;
    text-align: center;
    margin: 0;
  }
}
.titleRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 768px) {
  .titleRow {
    display: block;
  }
}
.name {
  margin: 0;
  margin-right: 32px;
  margin-right: 3.2rem;
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: 300;
  display: inline;
}
@media (max-width: 768px) {
  .name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 20px;
    text-transform: capitalize;
    color: #1f274c;
    margin-right: 0;
    text-align: center;
    display: block;
  }
}
.city {
  margin: 5px 0 35px;
}
@media (max-width: 768px) {
  .city {
    text-align: center;
    margin-bottom: 20px;
  }
}
.favorites {
  width: 400px;
  margin-left: 160px;
  float: left;
  text-align: left;
}
@media (max-width: 544px) {
  .favorites {
    clear: both;
    float: none;
    width: 100%;
    padding: 15px 30px;
    margin-left: 0;
  }
}
.workoutTag {
  display: inline-block;
  margin: 0 10px 30px 0;
  padding: 7px 15px;
  color: #fff;
  background: #6c6d72;
  border-radius: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: capitalize;
}
.workoutTag:last-child {
  margin-right: 0;
}
.workoutSong {
  font-size: 13px;
  font-size: 1.3rem;
  color: #757575;
}
.recentStudios {
  max-width: 400px;
}
.studio {
  font-size: 13px;
  font-size: 1.3rem;
}
.studio:after {
  content: ',\A0';
}
.studio:last-child:after {
  content: '';
}
.memberId {
}
@media (max-width: 768px) {
  .memberId {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16.5px;
    line-height: 20px;
    text-transform: capitalize;
    color: #333333;
  }
  .memberId span:first-child,
  .memberId span:last-child {
    white-space: nowrap;
    font-weight: 400 !important  ;
    margin-top: 8px;
  }
}
