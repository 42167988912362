.container{text-align:left;margin:0 5px 25px}
.field{float:right}
.label{display:block;width:100%;padding:0 15px;padding:0 1.5rem;font-size:16px;font-size:1.6rem;line-height:36px;line-height:3.6rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.toggle{display:inline-block;position:relative;margin:5px;margin:.5rem;border:1px solid #757575;background:#fff;color:#757575;border-radius:1.6rem;-webkit-transition:background .1s;transition:background .1s;cursor:pointer}
.toggle:hover{background:#edf4fd}
.toggle.focused{margin:3px;margin:.3rem;border:3px solid #2571cb}
:global(.mouse_or_touch_user) .toggle.focused{margin:5px;margin:.5rem;border:1px solid #2b4596}
.toggleOn{background:#2b4596;border-color:#2b4596;color:#fff}
.toggleOn .circle{left:25px}
.toggleOn.focused{margin:3px;margin:.3rem;border:3px solid #2571cb}
.toggleOn:hover{background:#2b4596}
.input{position:absolute;left:0;top:0;height:0;width:0;overflow:hidden;margin:0;z-index:-2;-webkit-box-sizing:border-box;box-sizing:border-box;cursor:inherit;pointer-events:all;outline:none}