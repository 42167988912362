.container {
  display: block;
}

.fields {
  display: block;
  width: calc(100vw - 32px);
  margin: 0 auto;
}

.search {
  display: block;
}

.comboBoxContainer {
  display: block;
  width: 100%;
}

.comboBoxContainer input {
  font-size: 1.4rem;
  height: 4.5rem;
  border-radius: 0.1rem;
  width: 100%;
  min-height: 4rem;
  padding: 0.8rem 1.6rem;
  text-overflow: ellipsis;
}

.comboBoxContainer ul {
  display: none;
}

.comboBoxContainer[data-show-suggestions='true'] ul {
  display: block;
  position: absolute;
  width: 100%;
  top: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  border: 2px solid #222b56;
  border-top: none;
  z-index: 12000;
}

.comboBoxContainer ul li {
  position: relative;
  display: block;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #757575;
  text-align: left;
  cursor: pointer;
}

.filtersContainer {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  border-top: 1px solid #ddd;
  margin: 0;
  max-height: calc(100vh - 64px - 80px);
}

.filtersContainer {
  border-bottom: none;
}

.filtersItem {
  border: none;
}

.filtersItem label {
  width: auto;
}

.sortContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sortContainer > * {
  font-size: 14px !important;
  margin: 0 !important;
}

.sortContainer button {
  border: none;
}

.sortContainer button:focus {
  outline: none;
}

.sortContainer .sortOptions {
  top: -160px;
}

.indicator {
  top: 152px;
  transform: rotate(180deg);
}