.login-page-form-wrapper {
  width: 790px;
  background: #ffffff;
  border-radius: 4px;
  margin: auto;
  padding: 48px 0px;
  max-width: 100vw;

  .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 24px;
    margin-top: 0px;
    text-align: center;
  }

  .login-form {
    width: 380px;
    margin: auto;
    max-width: calc(100vw - 32px);

    .copy {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 24px;
    }

    .custom-input {
      margin-bottom: 16px;
    }

    .custom-input input {
      background: #ffffff;
      border-radius: 4px;
      border: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      padding: 14px 20px;
      margin: 0;
      width: 100%;
    }
    .custom-input-wrapper {
      box-shadow: 0px 0px 8px rgba(34, 43, 86, 0.2);
    }
    .custom-input input:focus {
      outline: 1px solid #538fe9;
      padding: 14px 20px;
      border: none;
    }
    .custom-input.error input {
      outline: 1px solid #ff5252;
    }

    .error-info {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ff5252;
      margin: 8px 0px;
    }

    .forget-password {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #538fe9;
    }
  }

  .sign-up-link {
    padding: 0;
    margin: 48px 0 0;
    text-align: center;
    height: 28px;
    line-height: 28px;
    font-size: 18px;
    color: #222B56;
  }
}
