.container {
  min-height: 300px;
}

@media (max-width: 768px) {
  .map {
    min-height: 100px;
    height: 150px;
  }
}
