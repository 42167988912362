.grid {
  position: relative;
  display: inline-block;
  min-width: 100%;
  min-height: 200px;
  min-height: 20rem;
  clear: both;
  background: #fff;
  padding: 10px;
  padding: 1rem;
}
@media (max-width: 544px) {
  .grid {
    display: block;
  }
}
.result {
  min-width: 308px;
  min-width: 30.8rem;
  padding: 10px;
  padding: 1rem;
  float: left;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 50%;
}
@media (max-width: 645px) {
  .result {
    clear: both;
    width: 100%;
    padding: 0;
    margin-bottom: 1rem;
  }
}
.notFound {
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
  padding: 40px 20px;
  padding: 4rem 2rem;
  margin: 15px auto;
  margin: 1.5rem auto;
  max-width: 360px;
  max-width: 36rem;
}
.notFoundHeader {
  margin: 0 auto 10px;
  margin: 0 auto 1rem;
}
.locationIcon {
  width: 64px;
  width: 6.4rem;
  height: 64px;
  height: 6.4rem;
  margin-bottom: 30px;
  margin-bottom: 3rem;
}
.accountButton {
  margin-top: 15px;
  margin-top: 1.5rem;
}
.studios {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding-top: 24px;
}
.studioCTA {
  margin: 10px;
  margin: 1rem;
  padding: 10px;
  padding: 1rem;
  background: #fff;
}
.studioCTA .studioCTAInner {
  margin: 40px auto;
  margin: 4rem auto;
}
.studioCTA h3 {
  display: inline-block;
  margin: 8px 0;
  margin: 0.8rem 0;
}
@media (max-width: 544px) {
  .studioCTA {
    padding: 1.5rem;
    margin: 0;
  }
  .studioCTA .studioCTAButton {
    float: none;
    margin: 1.5rem;
  }
}
.studioCTAButton {
  float: right;
}
.seeMoreClasses {
  width: 220px;
  width: 22rem;
  margin: 48px auto;
  margin: 4.8rem auto;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  line-height: 2.2rem;
  text-align: center;
  color: #757575;
}
.seeMoreClasses a {
  display: block;
  margin-top: 20px;
  margin-top: 2rem;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: underline;
}
.seeMoreClasses svg {
  display: block;
  margin: 0 auto 8px;
  margin: 0 auto 0.8rem;
}
