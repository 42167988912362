.container {
  position: relative;
  min-height: 50vh;
  margin-top: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .container.staticBanner {
    /* padding-top: 7.5rem; */
  }
}
@media (max-width: 544px) {
  .container.staticBanner {
    /* padding-top: 9rem; */
  }
}
.mouse_or_touch_user_container {
}
.keyboard_user {
}
.page {
  position: relative;
}
