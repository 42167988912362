.label {
  display: inline-block;
  margin: 0;
  margin-right: 16px;
  margin-right: 1.6rem;
  line-height: 40px;
  line-height: 4rem;
  font-size: 14px;
  font-size: 1.4rem;
  color: #343434;
  white-space: nowrap;
}
@media (max-width: 544px) {
  .label {
    margin-bottom: 0.6rem;
    font-weight: 700;
    color: #222b56;
    font-size: 20px;
  }
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
@media (max-width: 544px) {
  .container {
    width: 100%;
  }
}
.dropdown {
  margin-left: 7px;
  position: relative;
  padding: 0;
  float: none;
  vertical-align: middle;
  background: transparent;
}
.dropdown[data-open='true'] .dropdownContent {
  display: block;
}
@media (max-width: 544px) {
  /* .dropdown {
    float: right;
  } */
}
.dropdownButton {
  display: inline-block;
  
  padding: 0 16px;
  padding: 0 1.6rem;
  border: 1px solid #757575;
  border-radius: 3px;
  color: #757575;
  background: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  vertical-align: middle;
  line-height: 48px;
  line-height: 4.8rem;
}
.dropdownButton svg {
  margin-left: 16px;
  margin-left: 1.6rem;
}
.dropdownButton:focus {
  border: 1px solid transparent;
  background: #fff;
  outline: 3px solid #2571cb;
  outline: 0.3rem solid #2571cb;
  outline-offset: 1px;
  outline-offset: 0.1rem;
  -webkit-transition: outline-width 0.2s;
  transition: outline-width 0.2s;
}
.dropdownContent {
  display: none;
  width: 150px;
  top: 40px;
}
.dropdownItem {
  font-weight: 300;
}
.dropdownItem[aria-selected='true'],
.dropdownItemActive {
  background: #2b4596;
  color: #fff;
}
.dropdownItemActive {
}
.title {
  margin: 20px;
  font-size: 22px;
  font-size: 2.2rem;
}
.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
