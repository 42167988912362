.form {
  margin: 0 auto;
  text-align: left;
}
.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
}
.copy {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.container {
  background: #ffffff;
  border-radius: 4px;
  width: 790px;
}
@media (max-width: 320px) {
  .container {
    border: none;
    padding: 1.6rem;
    margin: 0;
  }
}
.modal {
  width: 375px;
  width: 37.5rem;
}
