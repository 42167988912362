.avatar {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  color: #222b56;
}
@media (max-width: 768px) {
  .avatar {
    width: 100px;
    height: 100px;
  }
}
.image {
  overflow: hidden;
  background-color: #fff;
  background-size: cover;
}
.image,
.noImage {
  height: 100%;
  border-radius: 50%;
}
.noImage {
  width: 100%;
  background: #fff;
}
