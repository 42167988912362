.reservation {
  position: relative;
  background: #fff;
  text-align: left;
  margin-bottom: 30px;
  min-height: 440px;
}
@media (max-width: 544px) {
  .reservation {
    min-height: 300px;
  }
}
.myUpcoming {
  min-height: inherit;
}
@media (max-width: 544px) {
  .myUpcoming {
    min-height: inherit;
  }
}
.focusedMyUpcoming {
  -webkit-box-shadow: 0 0 5px #222b56;
  box-shadow: 0 0 5px #222b56;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.content {
  border: 1px solid #ddd;
  border-top: none;
}
.chip {
  margin-right: 0;
  background: #222b56;
  font-size: 14px;
  font-size: 1.4rem;
}
.info {
  padding: 15px 20px 0;
  height: 160px;
}
@media (max-width: 544px) {
  .info {
    min-height: 125px;
  }
}
.date {
  margin: 0 0 17px;
  line-height: 22px;
  line-height: 2.2rem;
  font-size: 18px;
  font-size: 1.75rem;
}
.date,
.tiny {
  font-weight: 300;
}
.tiny {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 0 15px;
}
.actions {
  padding: 1px 20px 0;
}
.action {
  display: block;
  text-align: center;
  line-height: 50px;
  line-height: 5rem;
  border-top: 1px solid #757575;
  color: #e61761;
  margin: 0;
  width: 100%;
}
.action:hover {
  text-decoration: underline;
}
.action:focus {
  outline: 3px solid #2571cb;
  outline: 0.3rem solid #2571cb;
  outline-offset: 1px;
  outline-offset: 0.1rem;
}
.noShow {
  color: #e61761;
}
.noShow:focus,
.noShow:hover {
  text-decoration: underline;
}
.button {
  width: 100%;
  margin: 0 0 20px;
  text-align: center;
}
.calendarIcon {
  position: absolute;
  fill: #fff;
  top: 154px;
  top: 15.4rem;
  right: 14px;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.calendarIcon:focus,
.calendarIcon:hover {
  fill: #fff;
}
.noCancel {
  min-height: 50px;
  min-height: 5rem;
  padding: 16px 0;
  padding: 1.6rem 0;
  border-top: 1px solid #757575;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
  color: #757575;
}

.actions a {
  color: #e61761;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .calendarIcon {
    top: 10rem;
  }
  .actions {
    padding: 0px;
  }
  .action {
    border-top: 1px solid #e5e5e5;
    padding: 0px 20px;
    text-align: left;
  }
  .action a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 44px;
    color: #2b4596;
  }
}
