.container{min-height:250px;padding:64px 0;padding-bottom:32px;bottom:0;background:#222b56;color:#fff;text-align:center;font-size:16px;font-size:1.6rem}.content{max-width:1200px;margin:0 auto}.inner{padding:16px}.col{padding-right:88px;text-align:left}@media (max-width:768px){.col{padding:0 45px;margin-bottom:30px}.col:last-child{margin-bottom:0}}.connectWithUs{margin:24px 0 20px;font-size:16px;font-size:1.6rem}.socialLinks{margin-bottom:24px}.trademark{display:inline-block;margin:32px 10px 6px;margin:3.2rem 1rem .6rem;line-height:1.5}.copyright{display:block}.moveLink{color:#fff;font-family:Poppins,Helvetica,Arial,sans-serif}.logo{width:190px;height:50px;fill:#fff}@media (max-width:768px){.logo{margin-bottom:30px}}.homeLink{}.homeLink.focus_outline:focus{outline-color:#fff}.appLink{margin:0 16px;margin-left:0;height:50px;display:inline-block;color:#fff}.appLink:last-child{margin-right:0}.appLink svg{height:50px;width:155px}.icon{width:16px;height:16px}@media only print{.container{display:none}}



.moveFooter{
    background:white;
    color: #222b56;
}

.moveFooter span{
    color: #222b56;
}

.moveFooter a{
    color: #222b56;
}
